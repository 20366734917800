import './feedback.scss';
import emailjs from 'emailjs-com';
import { useEffect, useState } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'

function FeedBack() {
    useEffect(() => {
        Aos.init({ duration:2000 });
    }, []);

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [nameError, setNameError] = useState('Поле имя не может быть пустым');
    const [emailError, setEmailError] = useState('Поле почта не может быть пустым');
    const [emailDirty, setEmailDirty] = useState(false);
    const [nameDirty, setNameDirty] = useState(false);
    const [formValid, setFormValid] = useState(false);

    useEffect(() =>{
        if(emailError || nameError) {
            setFormValid(false);
        } else {
            setFormValid(true);
        }
    }, [emailError, nameError])

    const emailHandler = (e) => {
        setNumber(e.target.value)
        const re = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
        if (!re.test(e.target.value)){
            setEmailError()
        } else {
            setEmailError()
        }
    }

    const nameHandler = (e) => {
        setName(e.target.value)
        if(e.target.value.length < 2){
            setNameError("Некорректное имя")
        } else {
            setNameError("")
        }
    }

    const blurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                setEmailDirty(true)
                break
            case 'name':
                setNameDirty(true)
                break
        }
    }

    const nameColor = nameError && nameDirty?"red":"";
    const emailColor = emailError && emailDirty?"red":"";

    const submitSuccess = () => {
        alert("Письмо было отправлено")
    }

    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm('service_q07v17u', 'template_mgl75ld', e.target, 'user_Za7hdgLaMYNMlgFzcMCyO')
        emailjs.sendForm('service_q07v17u', 'template_91zhvih', e.target, 'user_Za7hdgLaMYNMlgFzcMCyO')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }

    return(
        <div className="feedback">
            <div className="feedback_info">
                <h1 className="feedback_title">Обратная связь</h1>
                <div className="feedback_line_block">
                    <div className="feedback_line">
                        <hr/>
                    </div>
                </div>
                <div className="feedback_block">
                    <div data-aos="fade-right" className="feedback_items">
                        <div className="feedback_item">
                            <i className="icon fas fa-home"></i>
                                Г. Таганрог Поляковское шоссе 16 (АБК-3), оф.303
                        </div>
                        <div className="item">
                            <i className="icon fas fa-phone"></i>
                                +7 (952) 609-12-74
                        </div>
                        <div className="item">
                            <i className="icon fas fa-envelope"></i>
                                taktimet@gmail.com
                        </div>
                    </div>
                    <form data-aos="fade-left" action="" className="feedback_form" onSubmit={sendEmail}>
                        <div className="feedback_inputs">            
                            <input style={{borderColor: nameColor}} onChange={e => nameHandler(e)} value={name} onBlur={e => blurHandler(e)} type="text" className="textb" placeholder="Ваше имя" name="name"/>                      
                            <input style={{borderColor: emailColor}} onChange={e => emailHandler(e)} value={number} onBlur={e => blurHandler(e)} type="number" className="textb" placeholder="Ваш номер телефона" name="number"/>
                        </div>
                        <textarea placeholder="Сообщение" name="message"></textarea>
                        <input onClick={submitSuccess} disabled={!formValid} type="submit" className="btn" />
                </form>
            </div>
            </div>
        </div>
    )
}

export default FeedBack;