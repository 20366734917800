import Aos from 'aos';
import { useEffect } from 'react';
import './advantage.scss';
import 'aos/dist/aos.css'

const Advantage = ({ items }) => {
    useEffect(() => {
        Aos.init({ duration:2000 });
    }, []);

        return (
            <div className="advantage"> 
                    <h1 className="advantage_title">Наши преимущества</h1>
                <div className="advantage_line_block">
                    <div className="advantage_line">
                        <hr />
                    </div>
                </div>
                <div data-aos="zoom-in-down" className="advantage_items">
                    {
                        items.map(item => (
                            <li className="advantage_item">
                                <img className="advantage_item_img" src={item.img} alt="321"/>
                                <span className="advantage_item_name">{item.name}</span>
                            </li>
                        ))}
                </div>      
            </div> 
        );
    }

export default Advantage;