import './header.scss';
import { Link } from 'react-scroll';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css'

export default function Header() {
    useEffect(() => {
        Aos.init({ duration:2000 })
    }, []);

    return(
        <div className="header">
            <div className="header_nav">
                <h1 data-aos="fade-right" className="header_title">тактимет</h1>
                <div className="header_nav_email_order">
                    <h1 data-aos="fade-left" className="header_email">taktimet@gmail.com</h1>
                    <Link activeClass="active" to="feedback" spy={true} smooth={true}><button data-aos="fade-left" className="header_order">Заказать</button></Link>          
                </div>
            </div>
            <div className="header_description">
                <span data-aos="fade-up" className="header_description_title">
                    Самый дешевый металлопрокат
                </span>
            </div>
            <div className="header_line_block">
                <div data-aos="fade-up" className="header_line">
                    <hr />
                </div>
            </div>  
            <div data-aos="fade-up" className="header_description_second">
                <span className="header_description_second_title">
                    На рынке с 2012 года
                </span>
            </div>
        </div>
    )
}