import './app.scss';
import Advantage from "./Components/Advantage/Advantage";
import Contacts from "./Components/Contacts/Contacts";
import FeedBack from "./Components/FeedBack/FeedBack";
import Header from "./Components/Header/Header";
import Production from "./Components/Production/Production";
import Services from "./Components/Services/Services";

function App() {
  const productVariental = ["Балка", "Швеллеры", "Арматура", "Уголок", "Катанка", "Круг", "Полоса", "Квадрат"]
  const productSheet = ["Лист г/к", "Лист х/к", "Лист оцинкованный", "Профнастил", "Просечно-вытяжной лист(ПВЛ)", "Лист рифленый", "Лист нержавеющий"]
  const productPipes = ["Трубы г/д", "Трубы х/д", "ВГП", "Электросварные трубы", "Трубопроводная арматура", "Трубы нержавеющие"]
  const productAlwaysAvailable = ["Дорожная сетка", "Нержавеющий прокат", "Шестигранники", "Цветной прокат", "Ленты", "Проволока", "Сетка", "Конструкционная сталь", "Инструментальная сталь", "Шпунт Ларсена"]

  return (
    <div className="App">
      <Header/>
      <Advantage
        items = {[
          {
            img: "quality.png",
            name: 'Гарантированно качественный метиллопрокат'
          },
          {
            img: "delivery.png",
            name: 'Быстрая доставка собственным транспортом'
          },
          {
            img: 'conditions.png',
            name: 'Особые условия для постоянных заказчиков'
          },
          {
            img: 'assortment.png',
            name: 'Полный ассортимент металлопроката',
          }
        ]}
      />
      <Production 
        items = {[
          {
             title: 'Сортовая продукция',
             products: productVariental.map((productVariental) => (
               <li>{productVariental}</li>
             ))
          },
          {
            title: 'Листовой прокат',
            products: productSheet.map((productSheet) => (
              <li>{productSheet}</li>
            ))
          },
          {
            title: 'Трубы',
            products: productPipes.map((productPipes) => (
              <li>{productPipes}</li>
            ))
          },
          {
            title: 'Всегда в наличии',
            products: productAlwaysAvailable.map((productAlwaysAvailable) => (
              <li>{productAlwaysAvailable}</li>
            ))
          }
        ]}
      />
      <Services 
        items = {[
          {
            services: 'Газовая резка'
          },
          {
            services: 'Плазменая резка'
          },
          {
            services: 'Рубка'
          }
        ]}
      />
      <Contacts
        items = {[
          {
            name: 'Адрес: ',
            description: 'г. Таганрог Поляковское шоссе 16 (АБК-3), оф.303'
          },
          {
            name: 'Телефон: ',
            description: '+7 (8634) 641-485'
          },
          {
            name: 'Менеджер: ',
            description: '+7 (952) 609-12-74'
          }
        ]}
      />
      <FeedBack/>
    </div>
  );
}

export default App;
