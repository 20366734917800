import './services.scss';
import 'aos/dist/aos.css'
import Aos from 'aos';
import { useEffect } from 'react';


const Services = ({ items }) => {
    useEffect(() => {
        Aos.init({ duration:4000 });
    }, []);

    return(
        <div className="services">
            <h1 className="services_title">
                Услуги
            </h1>
            <div className="services_line_block">
                <div className="services_line">
                    <hr/>
                </div>
            </div>
            <div data-aos="fade-top" className="services_items">
                {
                    items.map(item => (
                        <div className="services_item"> 
                            <h2 className="services_item_list">{ item.services }</h2>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Services;