import Aos from 'aos';
import { useEffect } from 'react';
import './production.scss'
import 'aos/dist/aos.css'

const Production = ( {items} ) => {
    useEffect(() => {
        Aos.init({ duration:2000 });
    }, []);

    return(
        <div className="production">
            <h1 className="production_title">
                Наша продукция
            </h1>
            <div className="production_line_block">
                <div className="production_line">
                    <hr/>
                </div>
            </div>
            <div data-aos="fade-up" className="production_items">
                {
                    items.map(item => (
                        <div className="production_item">
                            <h2 className="production_item_title">{item.title}</h2>
                            <li className="production_item_products">{item.products}</li>            
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Production;