import './contacts.scss';
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import Aos from 'aos';

const Contacts = ({items}) => {
    useEffect(() => {
        Aos.init({ duration:2000 });
    }, []);

    return(
        <div className="contacts">
            <h1 className="contacts_title">
                Наши контакты
            </h1>
            <div className="contacts_line_block">
                <div className="contacts_line">
                    <hr/>
                </div>
            </div>
            <div data-aos="fade-up" className="contacts_map">       
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2710.0803090762047!2d38.8688973!3d47.2150109!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40e157e0f1a46ea1%3A0x14c1cb6e4d7aaaea!2z0J_QoNCV0KHQodCc0JDQqA!5e0!3m2!1sru!2sru!4v1631111928215!5m2!1sru!2sru" 
                                    width="100%" 
                                    height="700px"
                                    
                                    loading="lazy">
                                </iframe>      
                </div> 
                <div data-aos="fade-up" className="contacts_items">
                    {
                        items.map(item => (
                            <div className="contacts_item">
                                <table>
                                    <h4>{item.name}</h4>
                                    <span>{item.description}</span>
                                </table>
                            </div>
                        ))
                    }                                                 
                </div>

            </div>
    )
}

export default Contacts;